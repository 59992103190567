import React from 'react'
import {Link} from "react-router-dom";

function Page404() {
  return (
    <div className='Page404'>
            <div className='flex flex-col'>
            <p className='text-8xl font-bold inline border-b-4 border-blue-600'>Error 404</p>
            <p className='text-4xl font-bold inline py-2'>Page not found</p>
            <p className='text-2xl font-bold inline py-2 text-blue-600'><Link to="/">Click to return on homepage</Link></p>
            </div>
    </div>
  )
}

export default Page404