import React from 'react'
import {Link} from "react-router-dom";

const Home = () => {
  return (
    <div name='Home' className='Home'>
        {/* Container */}
        <div className='container'>
            <p className='text-blue-400 font-bold'>Hi, my name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6] hover:pointer'>Nicola Montesi</h1>
            <h2 className='text-4xl sm:text7xl font-bold text-[#8892b0]'>Aspirant Web Developer.</h2>
            <p className='text-[#8892b0] py-4 max-w-[700px]'>I am a Informatic and Automation Engineering student from UNIVPM.</p>
            <div>
                <Link to="/projects"><button className='Home-button'>View my Projects</button></Link>
            </div>
        </div>
    </div>
  )
}

export default Home