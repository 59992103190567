import React, { useState } from 'react';
import Logo from '../assets/logo.png';
import {Link} from 'react-router-dom';
import { FaBars, FaTimes, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
export const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);


    return (
        <div className='navbar'>
            {/* Logo */}
            <div>
                <Link to="/">
                <img src={Logo} style={{ width: '200px' }}></img>
                </Link>
            </div>

            {/* Menu */}
            <ul className='hidden md:flex font-semibold'>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Me</Link></li>
                <li><Link to="/skills">Skills</Link></li>
                <li><Link to="/projects">My Projects</Link></li>
                <li><Link to="/contact">Contact Me</Link></li>
            </ul>

            {/* Hamburger */}
            <div onClick={handleClick} className='Hamburger'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            {/* Mobile menu */}
            <ul className={!nav ? 'hidden' : 'Mobile-menu'}>
                <li onClick={handleClick} className="py-6 text-4xl"><Link to="/">Home</Link></li>
                <li onClick={handleClick} className="py-6 text-4xl"><Link to="/about">About Me</Link></li>
                <li onClick={handleClick} className="py-6 text-4xl"><Link to="/skills">Skills</Link></li>
                <li onClick={handleClick} className="py-6 text-4xl"><Link to="/projects">My Projects</Link></li>
                <li onClick={handleClick} className="py-6 text-4xl"><Link to="/contact">Contact Me</Link></li>
            </ul>

            {/* Social icons */}
            <div className='Socials'>
                <ul>
                    <li className='Social-li bg-[#0a66c2]'><a className='Social-links' href='https://it.linkedin.com/in/nicola-montesi-987851242' target="blank">Linkedin <FaLinkedin size={30} /> </a></li>
                    <li className='Social-li bg-[#333333]'><a className='Social-links' href='https://github.com/nicolamont' target="blank">Github <FaGithub size={30} /> </a></li>
                    <li className='Social-li bg-[#bc2919]'><Link className='Social-links' to="contact">Email <HiOutlineMail size={30} /> </Link></li>
                    <li className='Social-li bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...'><a className='Social-links' href='https://www.instagram.com/nicola_montesi/' target="blank">Instagram <FaInstagram size={30} /> </a></li>
                </ul>
            </div>
        </div>
    )
}
