import React from 'react'
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import {Link} from "react-router-dom";

let Data = new Date();


function Footer() {
  return (
    <div className='Footer'>
        <div>
          
            <ul className=' text-2xl flex justify-between  px-4'>
                <li> © Lorenzo Brecciaroli</li>
                <li>{Data.getFullYear()}</li>
                <li className='flex'><a href='https://it.linkedin.com/' target="blank"><FaLinkedin className='m-2' /></a> <a href='https://github.com/' target="blank"><FaGithub className='m-2' /></a> <a href='https://www.instagram.com/' target="blank"><FaInstagram className='m-2' /></a></li>
            </ul>
        </div>
    </div>
  )
}

export default Footer