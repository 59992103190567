import React from 'react'

const Contatct = () => {
  return (
    <div name="Contact" className='Contact'>
        {/* getform.io */}
        <form method='POST' action='https://formspree.io/f/xnqrvqqz' className='flex flex-col max-w-[600px] w-full'>
          <br/><br/><br/><br/>
          <div className='pb-4'>
          <p className='text-4xl font-bold inline border-b-4 border-blue-600'>Contact me</p>
          <p className='py-4'>// Submit the form below or shoot me an email- <a href='mailto:nicola9211@gmail.com'><span className='font-bold hover:underline hover:cursor-pointer text-blue-600'>nicola9211@gmail</span></a></p>
          </div>
          <input className='bg-[#ccd6f6] my-4 py-2 text-blue-600 placeholder-black' type="text" placeholder = 'Name' name ='name'  required/>
          <input className='bg-[#ccd6f6] my-4 py-2 text-blue-600 placeholder-black' type="email" placeholder = 'Email' name ='email'  required/>
          <textarea className='bg-[#ccd6f6] my-4 py-2 text-blue-600 placeholder-black' name='message' rows="10" placeholder='Message' required></textarea>
          <button className='text-white border-2 hover:bg-blue-600 hover:border-blue-600 px-4 py-3 my-8 mx-auto flex items-center'>Send me</button>
        </form>
    </div>
  )
}

export default Contatct