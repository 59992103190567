import React from 'react'
import Profilo from '../assets/foto-profilo.jpg';
import Curriculum from '../Files/Curriculum.pdf';
const About = () => {
  return (
    <div className='About' name="About">
      {/* Container */}
      <div className='About-Container'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <br/> <br/> <br/>
            <p className='text-4xl font-bold inline border-b-4 border-blue-600'>About Me</p>
              <img className='Profilo' src={Profilo} style={{ width: '150px' }} ></img>
          </div>
          <div>
          </div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-4xl font-bold'>
              <p>Hi, i'm Nicola Montesi, nice to meet you.</p>
            </div>
            <div>
              <p>I am a student of Computer Engineering and Automation at the University of Ancona, with a passion for programming, specially for web development. I like to challenge myself and test my limits with the aim of overcoming them and therefore always raising the level.
                   From an early age a lover of technology, discover what lies behind how things work.
                   Always ready to explain any reasoning and to learn from their mistakes with the aim of being able to improve.</p>
            </div>
          </div>
          <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8 px-4'>
          <a href={Curriculum} target= "blank"><button className='Home-button sm:ml-12 mt-4'>Download my Cv</button></a>
          </div>
      </div>
    </div>
  )
}

export default About