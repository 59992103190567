import React from 'react'
import HTML from '../assets/html-5.png';
import CSS from '../assets/css-3.png';
import Js from '../assets/js.png';
import PHP from '../assets/php.png';
import Java from '../assets/java.png';
import Mysql from '../assets/mysql.png';
import GITHUB from '../assets/github.png';
import REACT from '../assets/react.png';
const Skills = () => {
  return (
    <div className='Skills' name="Skills">
        <div className='Skills-container'>
        <div className='md:hidden'>
        <br/><br/><br/>
        </div>
          <div>
            <p className='text-4xl inline border-b-4 border-blue-600 font-bold'>My Skills</p>
            <p className='py-4'>// These are the tecnologies i've worked with:</p>
          </div>
          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={HTML}></img>
              <p className='font-bold my-4'>HTML</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={CSS}></img>
              <p className='font-bold my-4'>CSS</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={Js}></img>
              <p className='font-bold my-4'>Javascript</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={PHP}></img>
              <p className='font-bold my-4'>PHP</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={Java}></img>
              <p className='font-bold my-4'>Java</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={Mysql}></img>
              <p className='font-bold my-4'>Mysql</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={REACT}></img>
              <p className='font-bold my-4'>ReactJs</p>
            </div>
            <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={GITHUB}></img>
              <p className='font-bold my-4'>Github</p>
            </div>
          </div>
          <p className='font-bold text-center py-8 text-2xl'>I'm a beginner with React</p>
        </div>

    </div>
  )
}

export default Skills