import React from 'react'
import TESLA from '../assets/tesla-page.png';
import TODO from '../assets/todolist.png';
import CALCULATOR from '../assets/calculator.png';
import NOTES from '../assets/sticky-notes.png';


const Work = () => {
  return (
    <div className='Work' name="Work">
        <br/><br/><br/><br/>
        <div className='Work-container'>
            <div className='pb-8 py-4'>
                <p className='text-4xl font-bold inline border-b-4 border-blue-600'>My Projects</p>
                <p className='py-4'>// Check out some of my recent projects</p>
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                <div style={{backgroundImage: `url(${TESLA})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover effects */}
                    <div className='opacity-0 group-hover:opacity-100 '>
                        <span className='text-2xl font-bold text-gray-700 tracking-wider py-6'>
                        Tesla landing page
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://github.com/nicolamont/Tesla-landing-page' target="blank">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-blue-200 text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${NOTES})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover effects */}
                    <div className='opacity-0 group-hover:opacity-100 '>
                        <span className='text-2xl font-bold text-gray-700 tracking-wider py-6'>
                        Sticky-notes
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://github.com/nicolamont/Sticky-Notes-v1.0' target="blank">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-blue-200 text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${TODO})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover effects */}
                    <div className='opacity-0 group-hover:opacity-100 '>
                        <span className='text-2xl font-bold text-gray-700 tracking-wider py-6'>
                        To do list app
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://github.com/nicolamont/React-to-do-list' target="blank">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-blue-200 text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${CALCULATOR})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover effects */}
                    <div className='opacity-0 group-hover:opacity-100 '>
                        <span className='text-2xl font-bold text-gray-700 tracking-wider py-6'>
                        Calculator
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://github.com/nicolamont/Calculator' target="blank">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-blue-200 text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <a href='https://codepen.io/kinderpingui' target = "blank"><p className='font-bold text-center py-8 text-2xl hover:text-blue-600'>My Codepen Account</p></a>
            
        </div>
    </div>
  )
}

export default Work