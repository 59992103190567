import About from "./components/About";
import Contatct from "./components/Contatct";
import Home from "./components/Home";
import { Navbar } from "./components/Navbar";
import Skills from "./components/Skills";
import Work from "./components/Work";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Footer from "./components/Footer";
import Page404 from "./components/Page404";


function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Navbar/>
            <Home />
          </Route>
          <Route exact path="/about">
            <Navbar/>
            <About />
          </Route>
          <Route exact path="/projects">
            <Navbar/>
            <Work />
          </Route>
          <Route exact path="/skills">
            <Navbar/>
            <Skills />
          </Route>
          <Route exact path="/contact">
            <Navbar/>
            <Contatct />
          </Route>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
        
      </Router>

    </div>
  );
}

export default App;
